import firebase from 'firebase';

export const initializeFirebase = () => {
  firebase.initializeApp({
 	apiKey: "AIzaSyD51ezdVLVvTLStOEFHZmtEpqeVlHdbMcs",
    authDomain: "coronavirusmap-270514.firebaseapp.com",
    databaseURL: "https://coronavirusmap-270514.firebaseio.com",
    projectId: "coronavirusmap-270514",
    storageBucket: "coronavirusmap-270514.appspot.com",
    messagingSenderId: "254232767359",
    appId: "1:254232767359:web:1b590ac1b86d3fede7c84a",
    measurementId: "G-NTJMXT9J0Q"
  });

  if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('../firebase-messaging-sw.js')
  .then(function(registration) {
    console.log('Registration successful, scope is:', registration.scope);
  }).catch(function(err) {
    console.log('Service worker registration failed, error:', err);
  });
}

}

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    return token;
  } catch (error) {
    console.error(error);
  }
}
