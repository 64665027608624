import React, { useState }from 'react';
import ReactDOM from 'react-dom';
import mapboxgl from 'mapbox-gl';
import Moment from 'react-moment';
import WebFont from 'webfontloader';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import Loader from 'react-loader-spinner'
import ListGroup from 'react-bootstrap/ListGroup';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import uuid from 'react-uuid'
import Cookies from 'universal-cookie';
import ToggleButton from '@material-ui/lab/ToggleButton';
import * as Sentry from '@sentry/browser';
import 'whatwg-fetch'; 
import { askForPermissioToReceiveNotifications, initializeFirebase } from './push-notification';
import ReactGA from 'react-ga';
import AOS from 'aos';

ReactGA.initialize('UA-6684544-23');
ReactGA.pageview(window.location.pathname + window.location.search);

var feature = require('feature.js');

WebFont.load({
  google: {
    families: ['Cabin:400,500,700&display=swap', 'sans-serif']
  }
});

Sentry.init({dsn: "https://6dc5af40f3a6460185335895cc0b13aa@sentry.io/4596351"});
mapboxgl.accessToken = 'pk.eyJ1IjoiY29yb25hdmlydXNtYXAiLCJhIjoiY2s3Z2FvbzkxMGFrdjNucGVhZTNqN2lodSJ9.GcP3LfzX_icJQNU0Z-8Cww';

class CrowdsourcedStat extends React.Component {
    constructor(props) {
        super(props);

        this.state = { style: "", "heading": 'Unknown' };

    }

    componentDidMount() {
        if (this.props.symptoms === "flu") {

            this.setState(state => ({
              style: "symptoms-flu",
              heading: "Flu Symptoms"
            }));

        }

        if (this.props.symptoms === "any") {

            this.setState(state => ({
              style: "symptoms-any",
              heading: "Any Symptoms"
            }));

        }

        if (this.props.symptoms === "none") {

            this.setState(state => ({
              style: "symptoms-none",
              heading: "No Symptoms"
            }));

        }

    }
   
  render() {
        
    return  <li className={[this.state.style, 'list-group-item'].join(' ')}>
            <div className="stats-header">
            {this.state.heading}<br />
            <div className="stats-subtext">

            Pending Statistical Significance

            </div>
            </div>
            <div className="stats-num"><Loader type="ThreeDots" color="#fff" width="40" height="40" /></div>
            </li>
  }
}

class CrowdsourcedStats extends React.Component {

  render() {

        return <ListGroup>
                    <React.Fragment>
                        <CrowdsourcedStat symptoms="flu" />
                        <CrowdsourcedStat symptoms="any" />
                        <CrowdsourcedStat symptoms="none" />
                    </React.Fragment>
                </ListGroup>
                 
  }


}

class Features extends React.Component {
      render() {
        return <Carousel  controls={false} indicators={false}>
            <Carousel.Item>
        
                <div id="crowdsourced">
                <div className="smallheading"><img src="img/coronavirusmap.svg" alt="Coronavirus Map"/>
                <span>Crowdsourced through Coronavirus Map</span>
                </div>
                <CrowdsourcedStats />
                </div>
          
            </Carousel.Item>

            <Carousel.Item>
                <div className="wrap">
                <div className="features inner">
                    <img src="img/participation.svg" alt="" />
                    <h2>Voluntary Participation</h2> Take just a few seconds to report how you’ve been feeling. It’s free and 100% anonymous
                </div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                    <div className="wrap">
                <div className="features inner">
                    <img src="img/location.svg" alt="" />
                    <h2>Visualised Data</h2> Reports are collected and mapped with confirmed cases from Public Health England
                </div>
                </div>
            </Carousel.Item>

            <Carousel.Item>
                   <div className="wrap">
                <div className="features inner">
                    <img src="img/crowdsourced.svg" alt="" />
                    <h2>Crowdsourced Data</h2> Thousands of members of the public across the country also contribute daily to keep you safe
                </div>
                </div>
            </Carousel.Item>
        </Carousel>
    }
 
}

class ScrollDown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {shouldHide: false};
    }

    handleScroll = () => {  

        this.setState({shouldHide: true});

    }

    componentDidMount() {

        window.addEventListener("scroll", this.handleScroll);

    }

    render() {
         const shouldHide = this.state.shouldHide;
        return (
            
            <div className={shouldHide ? 'hidden' : 'd-none d-sm-block'}>
                <div data-aos="fade-in" data-aos-duration="3000" data-aos-delay="5000" className="arrow arrow-first"></div>
                <div data-aos="fade-in" data-aos-duration="3000" data-aos-delay="5000" className="arrow arrow-second"></div>
            </div>
        );

    }

}

class Map extends React.Component {
  
  componentDidMount() {

    if (feature.webGL) {

     const map = new mapboxgl.Map({
        attributionControl: false,
        container: 'map',
        style: 'mapbox://styles/coronavirusmap/ck7mm7n6c050o1imh2bornqpn',
        center: [-0.95, 52],
        zoom: 6.4,
        minZoom: 4,
        maxZoom: 9.8,
        showUserLocation: true,
        pitch: 40,
        maxBounds: [

            [
                -12.9033487202873,
                49.5
            ],

            [
                7.56963872670824,
                61
            ],

        ]
    });

     map.on('load', function() {

        map.addSource('phe_cases', {
            type: 'geojson',
            data: 'https://api.coronavirusmap.co.uk/cases/map'
        });

        map.addSource('phe_cases_points', {
            type: 'geojson',
            data: 'https://api.coronavirusmap.co.uk/cases/map/points'
        });

        map.addLayer({
            'id': 'phe_cases',
            'type': 'fill-extrusion',
            'source': 'phe_cases',
            'layout': {},
            "light": {
                  "anchor": "map",
                  "color": "#000",
                  "intensity": 0
                },
            'paint': {
                'fill-extrusion-height': ["*", 15, ['get', 'cases']] ,
                'fill-extrusion-vertical-gradient': true,
                'fill-extrusion-color': [
                    'interpolate',
                    ['linear'],
                    ['get', 'cases'],
                    100,
                    '#4A191D',
                    150,
                    '#632127',
                    200,
                    '#7C2A31',
                    250,
                    '#96323B',
                    300,
                    '#AF3B45',
                    350,
                    '#C94450'
                ],
                'fill-extrusion-opacity': 0.75
            }
        });

        map.setFilter('phe_cases', ['>', 'cases', 0]);

        map.addControl(new mapboxgl.FullscreenControl());

        map.on('click', 'phe_cases', function(e) {
new mapboxgl.Popup()
.setLngLat(e.lngLat)
.setHTML("<span style=\"font-size: 1.2em;\">" + e.features[0].properties.region + "</span>" + 
    "<br >" + e.features[0].properties.cases + ((e.features[0].properties.cases > 1) ? " Cases" : " Case"))
.addTo(map);
});

        map.addLayer({
            'id': 'cases_num',
            'type': 'symbol',
            'source': 'phe_cases_points',
            'layout': {
                'text-field': ['get', 'cases'],
                'text-font': ['Cabin Bold', 'Open Sans Semibold'],
                'text-anchor': 'center',
                'text-size': 16
            },
            'paint': {
                'text-color': '#fff',
                'text-halo-color': '#000',
                'text-halo-width': 0.8
            }
        });

        map.setFilter('cases_num', ['>', 'cases', 0]);

        map.addControl(new mapboxgl.AttributionControl({
            compact: true
        }));

    });

  }

  }   

    render() {
        return null
     }

}

class PHEStats extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cases: '', tested: '', last_updated: '' };
  }

  componentDidMount() {
    fetch('https://api.coronavirusmap.co.uk/cases')
    .then(results => {
      return results.json();
    }).then(data => {
      this.setState(state => ({
      cases: (data.national.positive).toLocaleString(navigator.language, { minimumFractionDigits: 0 }),
      tested: (data.national.tested).toLocaleString(navigator.language, { minimumFractionDigits: 0 }),
      last_updated: data.national.last_updated
      }));
    })
  }

  render() {
        
         return (
          <li id="stats-phe" className="list-group-item">
            <div className="stats-header">
            Confirmed COVID-19 Cases<br />
            <span className="stats-subtext">Based on {this.state.tested} individuals tested<br />
            Last Updated: <Moment format="ddd Do MMMM HH:mm">{this.state.last_updated}</Moment><br />
            Source: <a href="https://www.gov.uk/guidance/coronavirus-covid-19-information-for-the-public">Public Health England</a>
            </span>
            </div>
            <div className="stats-num">{this.state.cases}</div>
      </li>
    );


  }
}

class SymptomsHeader extends React.Component {

  render() {

        return <React.Fragment>
                        <SymptomsModal symptoms="good" />
                        <br />
                        <SymptomsModal symptoms="bad" />
                </React.Fragment>
                 
  }

}

export class SymptomsModalCommonFields extends React.Component {

     render() {

     return (
        <React.Fragment>
            <Form.Group>
                <Form.Label>Postcode</Form.Label>
                <Form.Control type="text" required autoFocus="on" size="10" name="postcode" id="postcode" onChange={this.props.handleInputChange} placeholder="AANN N" />
                <div className="postcode-sector">Your postcode will be automatically truncated to sector level to ensure anonymity</div>
            </Form.Group>
            <Form.Group>
                <Form.Label>Age in years</Form.Label>
                <Form.Control className="form-num" required inputMode="numeric" pattern="[0-9]*" type="number" size="3" min="1" max="110" name="age" id="age" onChange={this.props.handleInputChange} />
            </Form.Group>

            <Form.Group>
                <Form.Label>Gender</Form.Label>
                 <Form.Row>
                <Form.Check
                  type="radio"
                  label="Male"
                  value="m"
                  name="gender"
                  className="form-gender"
                  required
                  onChange={this.props.handleInputChange}
                />
                <Form.Check
                  type="radio"
                  label="Female"
                  value="f"
                  name="gender"
                  className="form-gender"
                  required
                  onChange={this.props.handleInputChange}
                />
                </Form.Row>
            </Form.Group>
        </React.Fragment>
     )

    }

}

function SymptomSelector(props) {

    const [selected, setSelected] = React.useState(false);

    return (
        <ToggleButton
          value="check"
          selected={selected}
          onChange={() => {
            setSelected(!selected);
            props.handleInputChange([props.symptom['key'], !selected])
          }}
        >
        {props.symptom['symptom']}
        </ToggleButton>
    );

}

function SymptomTemperature(props) {

    if (props.visible) {
        return(
            <React.Fragment>
            <Form.Group>
            <Form.Label>Temperature</Form.Label>
            <p>If you don't have a thermometer to hand please leave this blank.</p>
            <InputGroup className="form-temperature">

                 <Form.Control
                  type="numeric"
                  placeholder="37.6"
                  name="temperature"
                  id="temperature"
                  inputMode="decimal"
                  onChange={props.handleInputChange}
                />
                <InputGroup.Append>
                    <InputGroup.Text>&deg;C</InputGroup.Text>
                </InputGroup.Append>
            </InputGroup>
        </Form.Group>
        <br />
        </React.Fragment>
        )
    } else {

        return null

    }
}


class SymptomsModalBody extends React.Component {

    render() {

        if (this.props.symptoms === "good") {

            return (<React.Fragment>
                    Great to hear you're feeling ok at the moment. Please enter your details below to help us track the spread of COVID-19.
                    <br />
                    <br />
                    <SymptomsModalCommonFields handleInputChange={this.props.handleInputChange} />
                    </React.Fragment>)

        } else {    

            const symptomButtons = this.props.symptomsList.map((symptom, key) =>
                <SymptomSelector symptom={symptom} handleInputChange={this.props.handleInputChange}></SymptomSelector>
            );

            return (<React.Fragment>
                Sorry to hear you aren't feeling very well at the moment. Please note not all the symptoms below are indicators of COVID-19 but are intended to study malaise within the population. 
                <br />
                <br />
                <Form.Group>
                    <Form.Label>Symptoms</Form.Label>
                    <br />
                    {symptomButtons}
                     <br />
                </Form.Group>

                <SymptomTemperature visible={this.props.temperatureVisible} handleInputChange={this.props.handleInputChange}/>

                <Form.Group>
                    <Form.Label>When did your symptoms start?</Form.Label>
                    <p>It doesn’t matter if you aren’t sure about the exact date, but try to be as accurate as you can.</p>
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>Day</Form.Label>
                            <Form.Control className="form-num" placeholder="DD" inputMode="numeric" pattern="[0-9]*" type="number" size="2" min="1" max="31" name="symptoms_onset_day" id="symptoms_onset_day" onChange={this.props.handleInputChange} />
                        </Form.Group>
                        <Form.Group as={Col}>
                            <Form.Label>Month</Form.Label>
                            <Form.Control className="form-num" placeholder="MM" inputMode="numeric" pattern="[0-9]*"  type="number" size="2" min="1" max="12" name="symptoms_onset_month" id="symptoms_onset_month" onChange={this.props.handleInputChange} />
                        </Form.Group>
                    </Form.Row>
                </Form.Group>
                <hr />
                <SymptomsModalCommonFields symptoms={this.props.symptoms} handleInputChange={this.props.handleInputChange} />
                </React.Fragment>)

        }

    }

}

class SymptomsModal extends React.Component {

    constructor(props, context) {
        super(props, context);  

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);

        this.state = {
            show: false,
            isSubmitting: false,
            isError: false, 
            temperatureVisible: false,
            
            uuid: null,
            postcode: null,
            age: null,
            gender: null,
            asymptomatic: true,
            temperature: null,
            symptoms_onset: null,
            symptoms_onset_month: null,
            symptoms_onset_day: null
        };

        this.symptomsList = [{symptom: 'Fever', key: 'symptoms_fever'}, 
                             {symptom: 'Headache', key: 'symptoms_headache'}, 
                             {symptom: 'Diarrhea', key: 'symptoms_diarrhea'}, 
                             {symptom: 'Fatigue', key: 'symptoms_fatigue'}, 
                             {symptom: 'Nausea', key: 'symptoms_nausea'}, 
                             {symptom: 'Rash', key: 'symptoms_rash'}, 
                             {symptom: 'Cough', key: 'symptoms_cough'}, 
                             {symptom: 'Sore Throat', key: 'symptoms_sorethroat'}, 
                             {symptom: 'Body Aches', key: 'symptoms_bodyaches'}, 
                             {symptom: 'Chills / Night Sweats', key: 'symptoms_chills'}, 
                             {symptom: 'Shortness of Breath', key: 'symptoms_shortnessbreath'}, 
                             {symptom: 'Runny Nose', key: 'symptoms_runnynose'}, 
                             {symptom: 'Loss of Smell or Taste', key: 'symptoms_losstastesmell'}]

    }

    submitForm = async e => {

        if (e.currentTarget.checkValidity() === false) {

            e.preventDefault();
            e.stopPropagation();

        } else {

            e.preventDefault();
            this.handleClose()

        }

        this.setState({ isSubmitting: true });

        if (!this.state.asymptomatic) {

            this.state.symptoms_onset = '2020-' + this.state.symptoms_onset_month + '-' + this.state.symptoms_onset_day

        } else {

            this.state.symptoms_onset = null
        }

        this.payload = {uuid: this.state.uuid,
                        postcode: this.state.postcode,
                        age: this.state.age,
                        gender: this.state.gender,
                        asymptomatic: this.state.asymptomatic,
                        temperature: this.state.temperature,
                        symptoms_onset: this.state.symptoms_onset
                        }

        this.symptomsList.map((symptom, key) =>

            this.payload[symptom['key']] = (this.state[symptom['key']] ? this.state[symptom['key']] : false)

        );

        if (this.state.asymptomatic) {
            ReactGA.event({
              category: 'Symptoms',
              action: 'Asymptomatic Submission'
            });
        } else {
            ReactGA.event({
              category: 'Symptoms',
              action: 'Symptomatic Submission'
            });
        }      

        const res = await fetch("https://api.coronavirusmap.co.uk/submissions", {
          method: "POST",
          body: JSON.stringify(this.payload),
          headers: {
            "Content-Type": "application/json"
          }
        });
       
        this.setState({ isSubmitting: false });
        const data = await res.json();
        !data.hasOwnProperty("error")
          ? this.setState({ message: data.success })
          : this.setState({ message: data.error, isError: true });

        setTimeout(
          () =>
            this.setState({
              isError: false,
              message: ""
            }),
          1600
        );
    };

    handleInputChange = event => {

        if (Array.isArray(event)) {

            if (event[0] === "symptoms_fever") {

                this.setState({
                  temperatureVisible: event[1]
                })

            }

            this.setState({
                [event[0]]: event[1]
            });

        } else {

            const { name, value } = event.target;

            this.setState({
                [name]: value
            });

        }

    }

    handleClose() {

        if (this.state.asymptomatic) {
            ReactGA.event({
              category: 'Symptoms',
              action: 'Asymptomatic Model Closed'
            });
        } else {
            ReactGA.event({
              category: 'Symptoms',
              action: 'Symptomatic Model Closed'
            });
        }

        this.setState({ show: false });
        askForPermissioToReceiveNotifications();
    }

    handleShow() {

        if (this.state.asymptomatic) {
            ReactGA.event({
              category: 'Symptoms',
              action: 'Asymptomatic Model Opened'
            });
        } else {
            ReactGA.event({
              category: 'Symptoms',
              action: 'Symptomatic Model Opened'
            });
        }

        this.setState({ show: true });

    }

    componentDidMount() {

        this.symptomsList.map((symptom, key) =>

            this.setState({ [symptom['key']]: false })

        );          

        const cookies = new Cookies();

        if (cookies.get('uuid')) {

            this.setState({uuid: cookies.get('uuid')})

        } else {

            const visitor_uuid = uuid()

            this.setState({uuid: visitor_uuid})
            cookies.set('uuid', visitor_uuid)

        }

        if (this.props.symptoms === "good") {

            this.setState(state => ({
              buttonStyle: "symptoms-good",
              buttonTitle: "Great",
              buttonIcon: "img/heart.svg",
              asymptomatic: true
            }));

        }

        if (this.props.symptoms === "bad") {

            this.setState(state => ({
              buttonStyle: "symptoms-bad",
              buttonTitle: "Not Feeling Well",
              buttonIcon: "img/drugs.svg",
              asymptomatic: false
            }));

        }

    }

    render() {

        return (
             <React.Fragment>
                <Button className={this.state.buttonStyle} size="lg" onClick={this.handleShow}>
                   <img src={this.state.buttonIcon} alt="" /> {this.state.buttonTitle} <svg className="bi bi-chevron-right" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 01.708 0l6 6a.5.5 0 010 .708l-6 6a.5.5 0 01-.708-.708L10.293 8 4.646 2.354a.5.5 0 010-.708z" clipRule="evenodd"/>
</svg>
                </Button>

                <Modal show={this.state.show} onHide={this.handleClose}>
                    <Form onSubmit={this.submitForm}>
                        <Modal.Header>
                            <Modal.Title><img src="img/location.svg" alt="" /> Crowdsourced Symptoms</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <SymptomsModalBody symptoms={this.props.symptoms} symptomsList={this.symptomsList} temperatureVisible={this.state.temperatureVisible} handleInputChange={this.handleInputChange}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Row className="no-gutters">
                                <Col xs={9}>
                                <div id="modal-footer">No personally identifiable information (PII) is collected to ensure anonymity. Please opt-in to notifications to quickly self-report daily, even if you are well.</div> 
                                </Col>
                                <Col xs={3}>
                                <Button className="float-right" variant="primary" type="submit">Submit</Button>
                                </Col>
                            </Row>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </React.Fragment>
        );
    }
}

ReactDOM.render(<SymptomsHeader />,  document.getElementById('symptoms'));
ReactDOM.render(<Features />,  document.getElementById('features'));
ReactDOM.render(<ScrollDown />, document.getElementById('scroll'));
ReactDOM.render(<Map />, document.getElementById('root'));
ReactDOM.render(React.createElement(PHEStats), document.getElementById('phe'));
initializeFirebase();
AOS.init();
